import { useTranslation } from 'react-i18next';
import React from 'react';
import './App.css';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

function Tc() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center'}}>
      <div className="centeredContent">
        <div style={{ margin: '150px 0 0' }}><h2>{t('TermsConditions')}</h2></div>
        <div style={{fontSize: '12px'}}>
        V rámci naší aplikace pro finanční simulace dochází k shromažďování e-mailových adres uživatelů, které slouží výhradně pro účely zajištění plynulého přihlašovacího procesu. Zdůrazňujeme, že tyto údaje nejsou využívány pro marketingové kampaně ani jiné účely. Data jsou bezpečně uložena na serverech služby Back4App Inc., 440 N Wolfe Road, Sunnyvale, CA, USA, přičemž lokalita ukládání dat je buď v USA nebo v Evropě, a nejsou předávána třetím stranám.
        </div><div style={{fontSize: '12px', margin: '10px 0 0'}}>
        Jako jediná osoba, konkrétně Pavel Havlíček, IČO 07501668, sídlem Záhřebská 562/41, Vinohrady, 12000 Praha 2, mám přístup k těmto osobním údajům. Nicméně, tyto informace nejsou využívány pro žádné účely mimo nezbytné správy uživatelských účtů, včetně (a především kvůli) možnosti uživatelů obnovit si heslo samostatně.
        </div><div style={{fontSize: '12px', margin: '10px 0 0'}}>
        Uživatelé mají možnost v aplikaci zadávat osobní údaje do určených datových polí. Důrazně však doporučujeme, aby se zdrželi zadávání citlivých osobních informací z důvodu ochrany jejich soukromí.
        </div><div style={{fontSize: '12px', margin: '10px 0 0'}}>
        Webová stránka aplikace nevyužívá cookies a nejsou prováděny žádné marketingové ani reklamní kampaně.
        </div><div style={{fontSize: '12px', margin: '10px 0 0'}}>
        V souladu s nařízením GDPR mají uživatelé právo na přístup k jejich osobním údajům, jejich opravu, výmaz, omezení zpracování, právo na přenositelnost údajů, jakož i právo vznést námitku proti zpracování osobních údajů. Pro žádosti týkající se těchto práv nebo pro odstranění osobních údajů z naší databáze, prosím, kontaktujte mě na e-mailové adrese: peanuts.fintech@gmail.com.
        </div><div style={{fontSize: '12px', margin: '10px 0 30px'}}>
        Zavazujeme se k ochraně soukromí našich uživatelů a k transparentnosti v zpracování jejich osobních údajů.
        </div>
        <Button
            onClick={handleBack}
            variant="text"
            sx={{
                backgroundColor: 'white', 
                color: '#616161',
                marginRight: '8px',
            }}
            >
                {t('Back')}
        </Button>
      </div>
    </div>
  );
}

export default Tc;
