import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import './App.css';
import { useNavigate } from 'react-router-dom';
import PARSE_CONFIG from './config';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

function RestorePw() {
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const handleEmailChange = (event) => {
      const emailInput = event.target.value;
      setEmail(emailInput);
      setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput)); // Simple email regex validation
  };
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/');
  };
  const { t } = useTranslation();
  const handleRestore = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setLoading(true); // Start loading
  
    const requestOptions = {
        method: 'POST',
        headers: {
          'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
          'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
    };
  
    try {
      const response = await fetch('https://parseapi.back4app.com/requestPasswordReset', requestOptions);
      const data = await response.json();
      if (response.ok) {
        console.log('Request successful', data);
        setErrorMessage( t('PwRequested') );
        setIsErrorSnackbarOpen(true);
        setEmail(''); // Clear the email form by setting the email state back to an empty string
        setIsEmailValid(false);
        setTimeout(() => {
            navigate('/');
          }, 6000);
      } else {
        console.error('Login failed', data);
        setErrorMessage(data.error || 'An unknown error occurred'); // Set the error message
        setIsErrorSnackbarOpen(true); // Show the Snackbar
      }
    } catch (error) {
      console.error('Error during login', error);
      setErrorMessage('An error occurred while trying to reset password.'); // Set a generic error message
      setIsErrorSnackbarOpen(true); // Show the Snackbar
    } finally {
        setLoading(false); // Stop loading regardless of the outcome
    }
  };
  

  return (
    <div style={{ display: 'flex', justifyContent: 'center'}}>
      <div className="centeredContent">
        <div style={{ margin: '150px 0 0' }}><h2>{t('RestorePw')}</h2></div>
        <form noValidate autoComplete="off" onSubmit={handleRestore} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            label={t('Email')}
            variant="outlined"
            fullWidth
            onChange={handleEmailChange}
            error={!isEmailValid && email.length > 0} // Show error if email is not valid and not empty
            helperText={!isEmailValid && email.length > 0 ? t('InvalidEmail') : ''} // Assuming you have an 'InvalidEmail' entry in your i18n configuration
          />
          <Button
            variant="contained"
            type="submit"
            disabled={!isEmailValid || email.length === 0}
            startIcon={loading && <CircularProgress size={20} color="inherit" />}
            sx={{
                backgroundColor: '#ff9800', 
                color: 'white', 
                '&:hover': {
                  backgroundColor: '#e68900',
                  color: 'white',
                },
                marginRight: '8px',
            }}
            >
            {t('RestorePw')}
          </Button>
          {/* "Back" button */}
          <Button
            onClick={handleBack}
            variant="text"
            sx={{
                backgroundColor: 'white', 
                color: '#616161',
                marginRight: '8px',
            }}
            >
                {t('Back')}
          </Button>
        </form>
      </div>
      <Snackbar
        open={isErrorSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setIsErrorSnackbarOpen(false)}
        message={errorMessage}
      />
    </div>
  );
}

export default RestorePw;
