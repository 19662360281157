const currencies = [
    { code: 'CZK', name: 'Czech Crown' },
    { code: 'EUR', name: 'Euro' },
    { code: 'USD', name: 'United States Dollar' },
    { code: 'HUF', name: '' },
    { code: 'PLN', name: '' },
    { code: 'JPY', name: 'Japanese Yen' },
    { code: 'GBP', name: 'British Pound Sterling' },
    { code: 'AUD', name: 'Australian Dollar' },
    { code: 'SAR', name: '' },
    // Add more currencies as needed
  ];
  
  export default currencies;