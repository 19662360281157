import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreateAccountPage from './CreateAccountPage';
import LoginPage from './LoginPage';
import Header from './Header';
import Dashboard from './Dashboard';
import MainPage from './MainPage';
import RestorePw from './RestorePw';
import AccountPage from './AccountPage';
import Tc from './Tc';
import StatusPage from './StatusPage';


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/create-account" element={<CreateAccountPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/restore" element={<RestorePw />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/account-detail" element={<AccountPage />} />
        <Route path="/t-c" element={<Tc />} />
        <Route path="/status" element={<StatusPage />} />
        <Route path="/" element={<MainPage />} />
      </Routes>
    </Router>
  );
}

export default App;
