import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import './App.css';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';


function MainPage() {
  const { t } = useTranslation();
  const faqs = [
    {
      question: t('faqQuestion1'),
      answer: t('faqAnswer1'),
    },
    {
      question: t('faqQuestion2'),
      answer: t('faqAnswer2'),
    },
    {
      question: t('faqQuestion6'),
      answer: t('faqAnswer6'),
    },
    {
      question: t('faqQuestion4'),
      answer: t('faqAnswer4'),
    },
    {
      question: t('faqQuestion4a'),
      answer: t('faqAnswer4a'),
    },
    {
      question: t('faqQuestion4b'),
      answer: t('faqAnswer4b'),
    },
    {
      question: t('faqQuestion7'),
      answer: t('faqAnswer7'),
    },
    {
      question: t('faqQuestion3'),
      answer: t('faqAnswer3'),
    },
    {
      question: t('faqQuestion5'),
      answer: t('faqAnswer5'),
    },
    // Add more FAQs as needed
  ];
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/create-account');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundImage: `url(${process.env.PUBLIC_URL}/image_peanuts.webp)`, // Set the background image
        backgroundSize: 'cover', // Cover the entire page
        backgroundPosition: 'bottom', // Center the background image
        minHeight: '100vh'}} // Minimum height to fill the screen
      >
      <div className="centeredContent">
        <div 
              style={{
              padding: '20px', // Add some padding around the content
              backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent black background
              borderRadius: '5px', // Optionally, add rounded corners
              maxWidth: '100%', // Limit the width of the content area
              margin: '120px 0 0',}} 
            >
            <div style={{ margin: '0px 0', color: '#424242'  }}><strong>{t('Welcome')}</strong></div>
            <div style={{ margin: '20px 0', color: '#424242'  }}>{t('Welcome_2')}</div>
            <div style={{ margin: '20px 0', color: '#424242'  }}>{t('Welcome_3')}</div>
          </div>
        {/* Centered "Let go!" button */}
        <div style={{ margin: '30px 0 50px' }}>
          <Button
            fullWidth
            onClick={handleClick}
            sx={{
                backgroundColor: '#ff9800', 
                color: 'white', 
                '&:hover': {
                  backgroundColor: '#e68900',
                  color: 'white',
                },
                marginRight: '8px',
            }}
          >
            {t('LetGo')}
          </Button>
        </div>
        {/* FAQ Section */}
        <div>
          {faqs.map((faq, index) => (
            <Accordion key={index} sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background for each accordion
            }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography style={{ textAlign: 'left', color: '#424242' }}>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ textAlign: 'left', color: '#424242' }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        <div style={{ 
            margin: '30px 0 10px',
            fontSize: '16px',
            color: '#424242',
            backgroundColor: 'rgba(238, 238, 238, 0.92)', // Semi-transparent black background
            borderRadius: '3px', // Optionally, add rounded corners
            maxWidth: '100%',
              }}>
          © {new Date().getFullYear()} <a href="https://www.linkedin.com/in/pahavlicek/" target="_blank" rel="noopener noreferrer" style={{ color: '#424242' }}>Pavel Havlicek</a>. {t('Disclaimer')} <Link to="/status" style={{ color: '#424242' }}>{t('Status')}</Link> <Link to="/t-c" style={{ color: '#424242' }}>{t('TermsConditions')}</Link>
        </div>
      </div>
    </div>
  );
}

export default MainPage;

