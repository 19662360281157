import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import PARSE_CONFIG from './config'; // Import your Parse config

function StatusPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [interestDistributionData, setInterestDistributionData] = useState([]);
  const [aliquotInterestData, setAliquotInterestData] = useState([]);
  const [loadingInterestDistribution, setLoadingInterestDistribution] = useState(true);
  const [loadingAliquotInterest, setLoadingAliquotInterest] = useState(true);

  const formatDate = (date) => new Intl.DateTimeFormat(i18n.language, {
    year: 'numeric', month: 'long', day: 'numeric'
  }).format(new Date(date));

  useEffect(() => {
    const fetchInterestDistributionData = async () => {
      try {
        setLoadingInterestDistribution(true);
        const response = await fetch('https://parseapi.back4app.com/functions/getLastTenInterestDistribution', {
          method: 'POST',
          headers: {
            'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
            'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
            'Content-Type': 'application/json',
          }
        });

        const result = await response.json();
        if (response.ok && result.result) {
          setInterestDistributionData(result.result);
        } else {
          console.error('Failed to fetch interest distribution data', result);
          setInterestDistributionData([]); // Set data to an empty array if the response is not as expected
        }
      } catch (error) {
        console.error('Error fetching interest distribution data', error);
        setInterestDistributionData([]); // Set data to an empty array if there's an error
      } finally {
        setLoadingInterestDistribution(false);
      }
    };

    const fetchAliquotInterestData = async () => {
      try {
        setLoadingAliquotInterest(true);
        const response = await fetch('https://parseapi.back4app.com/functions/getLastTenAliquotInterest', {
          method: 'POST',
          headers: {
            'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
            'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
            'Content-Type': 'application/json',
          }
        });

        const result = await response.json();
        if (response.ok && result.result) {
          setAliquotInterestData(result.result);
        } else {
          console.error('Failed to fetch aliquot interest data', result);
          setAliquotInterestData([]); // Set data to an empty array if the response is not as expected
        }
      } catch (error) {
        console.error('Error fetching aliquot interest data', error);
        setAliquotInterestData([]); // Set data to an empty array if there's an error
      } finally {
        setLoadingAliquotInterest(false);
      }
    };

    fetchInterestDistributionData();
    fetchAliquotInterestData();
  }, [i18n.language]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div style={{ 
      display: 'flex',
      justifyContent: 'center',
      backgroundImage: `url(${process.env.PUBLIC_URL}/image_peanuts.webp)`,
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
      minHeight: '100vh'
    }}>
      <div className="centeredContent">
        <div style={{ margin: '120px 0 0' }}></div>
        
        <Typography variant="h6" style={{ color: 'white', margin: '20px 0' }}>{t('Last 10 interest distributions')}</Typography>
        {loadingInterestDistribution ? (
          <CircularProgress style={{ color: 'white', margin: '20px 0' }} />
        ) : (
          interestDistributionData.map((record, index) => (
            <Paper
              className="dashboardPaper2"
              key={index}
              style={{
                padding: '10px',
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 224, 178, 0.8)'
              }}
            >
              <Typography variant="body2">{formatDate(record.createdAt.iso)}</Typography>
              <Typography variant="body2" style={{ marginLeft: 'auto' }}>
                {record.message}
              </Typography>
            </Paper>
          ))
        )}

        <Typography variant="h6" style={{ color: 'white', margin: '20px 0' }}>{t('Last 10 daily aliquote interest calculations')}</Typography>
        {loadingAliquotInterest ? (
          <CircularProgress style={{ color: 'white', margin: '20px 0' }} />
        ) : (
          aliquotInterestData.map((record, index) => (
            <Paper
              className="dashboardPaper2"
              key={index}
              style={{
                padding: '10px',
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 224, 178, 0.8)'
              }}
            >
              <Typography variant="body2">{formatDate(record.createdAt.iso)}</Typography>
              <Typography variant="body2" style={{ marginLeft: 'auto' }}>
                {record.message}
              </Typography>
            </Paper>
          ))
        )}
        <div style={{ height: '80px' }}></div> {/* Add padding at the bottom */}
      </div>
      <Fab 
        color="secondary" 
        aria-label="go-back" 
        sx={{ position: 'fixed', bottom: 16, left: 16, backgroundColor: '#616161', '&:hover': { backgroundColor: '#e0e0e0' }}}
        onClick={handleGoBack}>
        <ArrowBackIcon />
      </Fab>
    </div>
  );
}

export default StatusPage;
