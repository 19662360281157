import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import PARSE_CONFIG from './config';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import DashboardIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';

Chart.register(LineElement, CategoryScale, LinearScale, PointElement);

function AccountPage() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [accountDetails, setAccountDetails] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const { accountId, sessionToken } = location.state;
  const formatDate = (date) => new Intl.DateTimeFormat(i18n.language, {
    year: 'numeric', month: 'long', day: 'numeric'
  }).format(new Date(date));

  const formatAmount = (amount) => new Intl.NumberFormat(i18n.language, {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount);
  const customDescriptionText = t('customInterestDistributionText');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [transactionType, setTransactionType] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [formErrors, setFormErrors] = useState({ amount: false, description: false });

  const fetchTransactions = useCallback(async () => {
    try {
      const response = await fetch('https://parseapi.back4app.com/functions/getTransactionsByAccount', {
        method: 'POST',
        headers: {
          'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
          'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accountId }),
      });
  
      const transactionData = await response.json();
      if (response.ok) {
        console.log('Transaction data:', transactionData);
        setTransactions(transactionData.result);
      } else {
        console.error('Failed to fetch transaction data', transactionData);
      }
    } catch (error) {
      console.error('Error fetching transaction data', error);
    }
  }, [accountId]);

  const fetchAccountDetails = useCallback(async () => {
    try {
      const response = await fetch('https://parseapi.back4app.com/functions/getAccountDetailsById', {
        method: 'POST',
        headers: {
          'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
          'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accountId }),
      });
  
      const accountDetails = await response.json();
      if (response.ok) {
        console.log('Account data:', accountDetails);
        setAccountDetails(accountDetails.result);
      } else {
        console.error('Failed to fetch account data', accountDetails);
      }
    } catch (error) {
      console.error('Error fetching account data', error);
    }
  }, [accountId]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const amountNumber = parseFloat(amount);
    let errors = {};
    errors.amount = !(amountNumber > 0 && amountNumber === parseFloat(amountNumber.toFixed(2))) || (transactionType === "WITHDRAWAL" && amountNumber > accountDetails.balance);
    errors.description = !(description.length >= 3 && description.length <= 50);
    setFormErrors(errors);
    if (!errors.amount && !errors.description) {
      try {
        const response = await fetch('https://parseapi.back4app.com/functions/handleTransaction', {
          method: 'POST',
          headers: {
            'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
            'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            account: accountId,
            amount: amountNumber,
            type: transactionType,
            description: description,
          }),
        });
  
        const data = await response.json();
        if (response.ok) {
          console.log('Transaction Successful', data);
          handleClose(); // Close the dialog on success
          await fetchTransactions(); // Reload transactions
          await fetchAccountDetails(); // Reload account details
        } else {
          console.error('Transaction Failed', data);
        }
      } catch (error) {
        console.error('Error submitting transaction', error);
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const [openEditModal, setOpenEditModal] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [editFormErrors, setEditFormErrors] = useState({ accountName: false, interestRate: false });

  const handleOpenEditModal = () => {
    setAccountName(accountDetails.description || '');
    setInterestRate(accountDetails.interest_rate || '');
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleEditSubmit = async () => {
    const interestRateNumber = parseFloat(interestRate);
    let errors = {
      accountName: !(accountName.length >= 3 && accountName.length <= 50),
      interestRate: !(interestRateNumber >= 1 && interestRateNumber <= 200 && interestRateNumber === parseFloat(interestRateNumber.toFixed(2)))
    };
    setEditFormErrors(errors);
  
    if (!errors.accountName && !errors.interestRate) {
      try {
        const response = await fetch('https://parseapi.back4app.com/functions/updateAccountData', {
          method: 'POST',
          headers: {
            'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
            'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            account: accountId,
            description: accountName,
            interest_rate: interestRateNumber,
          }),
        });
  
        const data = await response.json();
        if (response.ok) {
          console.log('Account update successful', data);
          handleCloseEditModal();
          await fetchAccountDetails();
        } else {
          console.error('Account update failed', data);
        }
      } catch (error) {
        console.error('Error submitting account update', error);
      }
    }
  };

  useEffect(() => {
    if (!sessionToken) {
      navigate('/login');
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true); // Start loading
        const userResponse = await fetch('https://parseapi.back4app.com/users/me', {
          method: 'GET',
          headers: {
            'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
            'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
            'X-Parse-Session-Token': sessionToken,
          },
        });

        const userData = await userResponse.json();
        if (userResponse.ok) {
          console.log('User data:', userData);
          await Promise.all([fetchTransactions(), fetchAccountDetails()]); // Wait for both fetches to complete
        } else {
          console.error('Failed to fetch user data', userData);
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching user data', error);
        navigate('/login');
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchData();
  }, [accountId, sessionToken, navigate, fetchTransactions, fetchAccountDetails]);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Fetch the data for the chart
    const fetchChartData = async () => {
      try {
        const response = await fetch('https://parseapi.back4app.com/functions/getDisplayBalances', {
          method: 'POST',
          headers: {
            'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
            'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            accountId: accountId,
          }),
        });

        const result = await response.json();
        if (response.ok && result.result) {
          let updatedChartData = result.result;
          if (updatedChartData.length > 0) {
            // Calculate the value to append
            const additionalValue = accountDetails.balance + accountDetails.aliquot_interest;
            // Append the value to the result array
            updatedChartData = [...updatedChartData, additionalValue];
          }
          setChartData(updatedChartData); // Update the state with the modified data
        } else {
          console.error('Failed to fetch chart data', result);
        }
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchChartData();
  }, [accountId, accountDetails.balance, accountDetails.aliquot_interest]);

  const data = {
    labels: chartData.map((_, index) => index + 1), // Labels are simply indexes
    datasets: [
      {
        data: chartData,
        borderColor: 'white',
        borderWidth: 4,
        fill: false,
        tension: 0.4, // Smoothing the line
        pointRadius: 0, // No points on the line
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false, // Hide X-axis
      },
      y: {
        display: false, // Hide Y-axis
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
    elements: {
      line: {
        borderColor: 'white',
        borderWidth: 4,
      },
    },
  };

  return (
    <div style={{ 
      display: 'flex',
      justifyContent: 'center',
      backgroundImage: `url(${process.env.PUBLIC_URL}/image_peanuts.webp)`,
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
      minHeight: '100vh'}}>
      <div className="centeredContent">
        {loading ? ( // Show loading spinner while loading
          <CircularProgress style={{ color: 'white', margin: '100px 0 0' }} />
        ) : (
          <>
            <div style={{ margin: '120px 0 0' }}></div>
            
            <Paper className="dashboardPaper2" 
              style={{
                position: 'relative',
                padding: '20px',
                marginBottom: '20px',
                backgroundColor: 'rgba(255, 224, 178, 0.8)',
                overflow: 'hidden', // Ensure chart doesn't overflow the box
              }}
            >
              <Typography 
                variant="h6" 
                style={{ textAlign: 'left', marginBottom: '20px' }}
              >
                <strong>{accountDetails.description}</strong>
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" style={{ textAlign: 'left', alignSelf: 'flex-end'  }}>
                  {t('Balance')}:
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'right' }}>
                  <strong>{formatAmount(accountDetails.balance)} {accountDetails.currency}</strong>
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" style={{ textAlign: 'left', alignSelf: 'flex-end'  }}>
                  {t('InterestRate')}:
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'right' }}>
                  {formatAmount(accountDetails.interest_rate)}% p.a.
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" style={{ textAlign: 'left', alignSelf: 'flex-end'  }}>
                  {t('TotalInterestEarned')}:
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'right' }}>
                  {formatAmount(accountDetails.total_interest_earned)} {accountDetails.currency}
                </Typography>
              </div>
              {/* Chart Area */}
              {chartData.length > 0 && (
                <div style={{ marginTop: '20px', height: '100px', position: 'relative' }}>
                  <Line data={data} options={options} />
                </div>
              )}
            </Paper>


            {transactions.length > 0 ? (
              transactions.map((transaction, index) => (
                <Paper
                  className="dashboardPaper2"
                  key={transaction.id}
                  style={{
                    padding: '10px',
                    marginBottom: index === transactions.length - 1 ? '100px' : '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor:
                      transaction.description === "INTEREST_DISTRIBUTION"
                        ? '#f1f8e9'
                        : transaction.amount < 0
                        ? '#ff8a65'
                        : '',
                  }}
                >
                  <div style={{ textAlign: 'left' }}>
                    <Typography variant="body2">{formatDate(transaction.date.iso)}</Typography>
                    <Typography variant="body2">
                      {transaction.description === "INTEREST_DISTRIBUTION"
                        ? customDescriptionText
                        : transaction.description}
                    </Typography>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Typography variant="body1">
                      {formatAmount(transaction.amount)} {transaction.currency}
                    </Typography>
                  </div>
                </Paper>
              ))
            ) : (
              <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: '20px', color: 'white' }}>
                {t('NoTransactionsYet')}
              </Typography>
            )}
          </>
        )}
      </div>
      <Fab 
        color="primary"
        aria-label="add" 
        sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: '#ff9800', '&:hover': { backgroundColor: '#e68900' } }} 
        onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
      <Fab 
        color="secondary" 
        aria-label="go-back" 
        sx={{ position: 'fixed', bottom: 16, left: 16, backgroundColor: '#616161', '&:hover': { backgroundColor: '#e0e0e0' }}}
        onClick={handleGoBack}>
        <DashboardIcon />
      </Fab>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('NewTransaction')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('NewTransactionText')}
          </DialogContentText>
          <FormControl fullWidth margin="normal">
            <InputLabel id="transaction-type-label">{t('TransactionType')}</InputLabel>
            <Select
              labelId="transaction-type-label"
              id="transaction-type"
              value={transactionType}
              label={t('TransactionType')}
              onChange={(e) => setTransactionType(e.target.value)}
              >
              <MenuItem value="TOPUP">{t('TopUp')}</MenuItem>
              <MenuItem value="WITHDRAWAL">{t('Withdrawal')}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            id="amount"
            label={t('Amount')}
            type="number"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            error={formErrors.amount}
            helperText={formErrors.amount ? t('AmountError') : ''}
          />
          <TextField
            margin="dense"
            id="description"
            label={t('Description')}
            type="text"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={formErrors.description}
            helperText={formErrors.description ? t('DescriptionError') : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {t('Submit')}
          </Button>
        </DialogActions>
      </Dialog>
      <Fab 
        color="primary" 
        aria-label="edit-account" 
        sx={{ position: 'fixed', bottom: 16, left: 80, backgroundColor: '#616161', '&:hover': { backgroundColor: '#e0e0e0' } }} 
        onClick={handleOpenEditModal}>
        <EditIcon />
      </Fab>

      <Dialog open={openEditModal} onClose={handleCloseEditModal} aria-labelledby="edit-account-form-title">
        <DialogTitle id="edit-account-form-title">{t('EditAccount')}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="account-name"
            label={t('AccountName')}
            type="text"
            fullWidth
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            error={editFormErrors.accountName}
            helperText={editFormErrors.accountName ? t('AccountNameError') : ''}
          />
          <TextField
            margin="dense"
            id="interest-rate"
            label={t('InterestRate')}
            type="number"
            fullWidth
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            error={editFormErrors.interestRate}
            helperText={editFormErrors.interestRate ? t('InterestRateError') : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>{t('Cancel')}</Button>
          <Button onClick={handleEditSubmit}>{t('SubmitEdit')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AccountPage;