import { useNavigate, useLocation  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, Button } from '@mui/material';
import './Header.css';

function Header() {
  const navigate = useNavigate();
  const location = useLocation(); // To determine the current route
  const isOnProtectedRoute = location.pathname === '/dashboard' || location.pathname === '/account-detail';
  const { t, i18n } = useTranslation();

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="header">
      <div className="headerText">
        Peanuts
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!isOnProtectedRoute && (
          <>
            <Button 
              onClick={() => navigate('/create-account')}
              sx={{
                backgroundColor: '#ff9800', 
                color: 'white', 
                '&:hover': {
                  backgroundColor: '#e68900',
                  color: 'white',
                },
                marginRight: '8px',
              }}
            >
              {t('SignUp')}
            </Button>
            <Button
              sx={{
                backgroundColor: 'white', 
                color: '#616161',
                marginRight: '8px',
              }}
              onClick={() => navigate('/login')}
            >
              {t('Login')}
            </Button>
          </>
        )}
        <Select
          value={i18n.language}
          onChange={handleChange}
          style={{ color: '#616161', backgroundColor: 'transparent', border: 'none', marginLeft: '10px' }}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="cs">CS</MenuItem>
          <MenuItem value="en">EN</MenuItem>
        </Select>
      </div>
    </div>
  );
}

export default Header;
