import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Translations for EN and CS
const resources = {
  en: {
    translation: {
      "Welcome": "Hi! Peanuts works as a notebook for children's pocket money. It builds their financial literacy foundations by crediting interest every Monday, thereby motivating kids to save money.",
      "Welcome_2": "Record all income (allowance from parents) and all expenditures (when children actually withdraw some money) in the account. As a parent, you can manage up to 4 accounts in different currencies in the app.",
      "Welcome_3": "I calculate the interest every night. Once a week, always early Monday morning, I credit that interest to the account. Kids will get more money that way! Isn't that cool?",
      "SignUp": "Sign Up",
      "Login": "Login",
      "LetGo": "Let's start!",
      "Email": "E-mail",
      "Password": "Password",
      "Lostpw": "I lost my password",
      "CreateAccount": "Create New Account",
      "Back": "Back",
      "Disclaimer": "The app may malfunction time to time. No actual money is managed. No cookies are used. Images by DALL-E.",
      "InvalidEmail":"Valid e-mail required",
      "PasswordTooShort": "Too weak password",
      "RestorePw": "Restore password",
      "PwRequested": "Instructions to restore password are in your e-mail inbox.",
      "Ahoj": "Hi! This is your dashboard.",
      "InterestRate": "Interest rate",
      "TotalBalance": "Total balance",
      "TotalInterestEarned": "Total interest earned",
      "AccountDetails": "Transactions",
      "customInterestDistributionText": "Received interest :)",
      "Cancel": "Cancel",
      "Submit": "Submit",
      "NewTransaction": "New transaction",
      "NewTransactionText": "To record new transaction, please fill out the form below.",
      "TransactionType": "Transaction type",
      "Withdrawal": "Withdrawal",
      "TopUp": "Top Up",
      "Amount": "Amount",
      "AmountError": "Enter valid number with max 2 decimals, withdrawal cannot exceed current balance",
      "Description": "Transaction description",
      "DescriptionError": "Description should have 3 - 50 characters",
      "NoTransactionsYet": "No transaction yet :( Click the plus button in the lower right corner.",
      "Balance": "Actual balance",
      "LogoutConfirmationTitle": "Logout",
      "AreYouSureYouWantToLogout":"You will be logged out.",
      "Logout": "Logout",
      "faqQuestion1": "For whom is the app suitable?",
      "faqAnswer1": "The app is suitable for parents and their children. The ideal age group for children is 6 to 13 years old.",
      "faqQuestion2": "How can I register?",
      "faqAnswer2": "Registration for the app is open to all. No fee is charged. You can manage up to 4 accouts under one login.",
      "faqQuestion3": "Who is behind the app and who takes care of it?",
      "faqAnswer3": "I created the app completely by myself, my wife came up with the name:) Most of the code is supplied by ChatGPT - I would never be able to achieve this without help of AI. Check my LinkedIn profile in the footer. Thanks to technology, there is no administrative work with the app operations; everything is set up and runs completely automatically.",
      "faqQuestion4": "How is interest calculated?",
      "faqAnswer4": "Just like in the bank on savings account, the priciple of compound interest is used. Every evening, the app calculates the daily accrued interest based on the current balance using the formula: daily interest = balance * interest rate * (1/360). Daily accrued interests accumulate in the account and are settled once a week - the accrued interest is credited to the account and reset. The bank will typicaly settle the interest one a month, but we do it every week!",
      "faqQuestion5": "What is the tech stack behind?",
      "faqAnswer5": "Backend and Database: Back4app. Frontend: React, Netlify. Regular jobs: Crontap. Repository: GitLab.",
      "TermsConditions": "Terms & Conditions",
      "SubmitEdit": "Submit changes",
      "EditAccount": "Edit account",
      "AccountName": "Account name",
      "AccountNameError": "Name should have 3-50 characters",
      "InterestRateError": "Set the rate within range of 1-200%, maximum 2 decimals.",
      "faqQuestion4a": "What happens if I change the interest rate on account?",
      "faqAnswer4a": "All upcoming daily accrued interests will be calculated with the new interest rate. No past calculations or interests will be affected.",
      "faqQuestion4b": "What does p.a. mean?",
      "faqAnswer4b": "It means per annum (yearly). 5% p.a. indicates your money will increase about 5% in one year.",
      "AddNewAccount": "Add new account",
      "MyNextAccount": "My next account",
      "EditGlobalCurrency": "Global currency setup",
      "CurrencyUpdateWarning": "Currency will be applied to all your accounts. Only currency will be updated, balances are not converted.",
      "Currency": "Currency",
      "faqQuestion6": "What interest rate is best to set?",
      "faqAnswer6": "When new account is created, the initial interest rate is set to 40% p.a. This seems like a lot compared to saving accounts, but rather low weekly interest would not motivate the kids very much. Should the balance increase by 1% every week, set 50% p.a. Should the amount double over period of one year, set the interest rate to 70% p.a.",
      "faqQuestion7": "I did not receive any interest on Monday, is the app malfunctioning?",
      "faqAnswer7": "No, the app is not malfunctioning. In case the balance on account is low and the interest rate is low, it may happen that daily accrued interest (after being rounded to 2 decimals) is zero. If the accumulated balance of daily accrued interests is zero, the app will not create any transaction for interest distribution.",
      "Last 10 daily aliquote interest calculations": "Last 10 daily aliquote interest calculations",
      "Last 10 interest distributions": "Last 10 interest distributions",
    }
  },
  cs: {
    translation: {
      "Welcome": "Ahoj! Peanuts funguje jako notýsek na kapesné pro děti. Buduje v nich základny finanční gramotnosti tím, že každé pondělí připisuje získaný úrok a motivuje tak děti ke spoření.",
      "Welcome_2":"Na účet si zapisujte všechny příjmy (kapesné od rodičů) a také všechny výdaje (když si děti skutečně vyberou nějaké peníze). Jako rodič můžete v aplikaci spravovat až 4 účty v různých měnách.",
      "Welcome_3":"Já každý večer spočítám úrok. Jednou za týden, vždy v pondělí brzo ráno, úrok připíšu na účet. Děti tak získají víc penízků! Není to skvělé?",
      "SignUp": "Vytvořit účet",
      "Login": "Přihlásit se",
      "LetGo": "Jdeme na to!",
      "Email": "E-mail",
      "Password": "Heslo",
      "Lostpw": "Zapomenuté heslo",
      "CreateAccount": "Založit nový účet",
      "Back": "Zpět",
      "Disclaimer": "Aplikace nemusí fungovat vždy správně. Žádné skutečné peníze nejsou spravovány. Nepoužíváme žádné cookies. Obrázky DALL-E.",
      "InvalidEmail":"Zadejte platný e-mail",
      "PasswordTooShort": "Heslo je příliš slabé",
      "RestorePw": "Obnova hesla",
      "PwRequested": "Do vašeho e-mailu byly zaslány instrukce pro obnovu hesla.",
      "Ahoj": "Ahoj! Tohle je tvůj přehled.",
      "InterestRate": "Úroková sazba",
      "TotalBalance": "Celkový zůstatek",
      "TotalInterestEarned": "Vyplacené úroky celkem",
      "AccountDetails": "Pohyby na účtu",
      "customInterestDistributionText": "Připsané úroky :)",
      "Cancel": "Zrušit",
      "Submit": "Odeslat platbu",
      "NewTransaction": "Nová platba",
      "NewTransactionText": "Pro zadání nové platby vyplňte pole níže",
      "TransactionType": "Typ transakce",
      "Withdrawal": "Výběr",
      "TopUp": "Vklad",
      "Amount": "Částka",
      "AmountError": "Zadejte platné číslo, maximálně 2 desetinná místa, výběr nesmí přesáhnout aktuální zůstatek",
      "Description": "Popis transakce",
      "DescriptionError": "Popis by měl mít 3 až 50 znaků",
      "NoTransactionsYet": "Žádná transakce tu ještě není :( Klikněte v pravém dolním rohu na tlačítko plus.",
      "Balance": "Aktuální zůstatek",
      "LogoutConfirmationTitle": "Odhlášení",
      "AreYouSureYouWantToLogout":"Budete odhlášeni.",
      "Logout": "Odhlásit",
      "faqQuestion1": "Pro koho je aplikace určena?",
      "faqAnswer1": "Aplikace je vhodná pro rodiče a jejich děti. Ideální věkové skupina dětí je 6 až 13 let.",
      "faqQuestion2": "Jak se můžu zaregistrovat?",
      "faqAnswer2": "Registrace do aplikace je otevřená všem. Není účtován žádný poplatek. Pod jedním přihlášením můžete spravovat až 4 účty.",
      "faqQuestion3": "Kdo za aplikací stojí a kdo se o ni stará?",
      "faqAnswer3": "Aplikaci jsem vytvořil úplně sám jako svůj sideproject, jméno vymyslela moje žena:) Většinu kódu dodal ChatGPT - bez AI bych aplikaci nikdy nebyl schopný sám vytvořit. Podívejte se na můj LinkedIn na odkazu v patičce. Díky technologiím není s provozem aplikace spojena žádná administrativa, vše je nastaveno a běží zcela automaticky.",
      "faqQuestion4": "Jak se počítá úrok?",
      "faqAnswer4": "Stejně jako v bance na spořícím účtu, jedná se o princip složeného úročení. Každý večer aplikace napočítá denní naběhlý úrok podle aktuální zůstatku pomocí výpočtu: denní úrok = zůstatek * úroková sazba * (1/360). Denní naběhlé úroky se kumulují na účtu a jednou týdně jsou vypořádány - kumulovaný naběhlý úrok se připíše na účet a vynuluje. Banka připisuje úroky jednou za měsíc, ale já to dělám každý týden!",
      "faqQuestion5": "Na jakých technologiích aplikace běží?",
      "faqAnswer5": "Backend a Databáze: Back4app. Frontend: React, Netlify. Pravidelné joby: Crontap. Repository: GitLab.",
      "TermsConditions": "Podmínky použití",
      "SubmitEdit": "Potvrdit změny",
      "EditAccount": "Upravit účet",
      "AccountName": "Jméno účtu",
      "AccountNameError": "Jméno by mělo mít 3 až 50 znaků.",
      "InterestRateError": "Nastavte prosím 1-200%, maximálně 2 desetinná čísla.",
      "faqQuestion4a": "Co se stane, když změním úrokovou sazbu na účtu?",
      "faqAnswer4a": "Všechny následující výpočty denního naběhlého úroku budou počítat se změněnou sazbou. Zpětně se nikdy nic nepřepočítává.",
      "faqQuestion4b": "Co znamená zkratka p.a.?",
      "faqAnswer4b": "Znamená per annum (ročně). 5% p.a. znamená, že penízky se zhodnotí zhruba o 5% za 1 rok.",
      "AddNewAccount": "Přidat další účet",
      "MyNextAccount": "Můj další účet",
      "EditGlobalCurrency": "Nastavení globální měny",
      "CurrencyUpdateWarning": "Nastavení se projeví na všech účtech. Dojde ke změně měny, nikoli ke konverzi zůstatků.",
      "Currency": "Měna",
      "faqQuestion6": "Jaký vysoký úrok nastavit?",
      "faqAnswer6": "Při založení nového účtu je automaticky nastavena úroková sazba 40% p.a., což se zdá hodně, ale při sazbách jako jsou na spořících účtech by vyplacený úrok byl velice malá částka. Pokud se má zůstatek na účtu zhodnotit o 1% každý týden, nastavete 50% p.a. Pokud chcete každou stokorunu během jednoho roku zdvojnásobit, nastavte 70% p.a.",
      "faqQuestion7": "V pondělí mi nepřišel žádný úrok, je aplikace rozbitá?",
      "faqAnswer7": "Ne, aplikace není rozbitá. V případě, že máte na účtu malý zůstatek a současně je nastavena nízká úroková sazba, může se stát, že denní naběhlý úrok je po zaokrouhlení na 2 desetinná místa nulový. Pokud je akumulovaný stav denních naběhlých úroků nula, pak aplikace žádnou transakci pro distribuci úroku nezaloží.",
      "Last 10 daily aliquote interest calculations": "Posledních 10 výpočtů denního naběhlého úroku",
      "Last 10 interest distributions": "Posledních 10 distribucí úroků",
    }
  }
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .use(LanguageDetector) // Detects user language
  .init({
    resources,
    lng: "cs", // Set initial language to CS
    fallbackLng: "cs",
    interpolation: {
      escapeValue: false, // React already safes from XSS
    }
  });

export default i18n;
