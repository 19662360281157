import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './App.css';
import currencies from './currencies';
import PARSE_CONFIG from './config';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete, Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';

function Dashboard() {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);  // Loading state
  const currency = accounts[0]?.currency || ''; // Assumes all accounts have the same currency
  const [openCurrencyDialog, setOpenCurrencyDialog] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(currency); // Use the initial global currency
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const sessionToken = location.state?.sessionToken;
  const totalBalance = accounts.reduce((acc, account) => acc + account.balance, 0);
  const totalInterestEarned = accounts.reduce((acc, account) => acc + account.total_interest_earned, 0);
  const navigate = useNavigate();
  const handleTileClick = (accountId) => {
    navigate('/account-detail', { 
      state: { 
        accountId: accountId, 
        sessionToken: sessionToken,
      }});
  };

  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [userId, setUserId] = useState(null);
  const handleOpenLogoutDialog = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };
  const fetchAccounts = useCallback(async (userId) => {
    try {
      setLoading(true);  // Start loading
      const response = await fetch('https://parseapi.back4app.com/functions/getAccountsByUser', {
        method: 'POST',
        headers: {
          'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
          'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userId,
        }),
      });
  
      const accountsData = await response.json();
      if (response.ok) {
        console.log('Accounts data:', accountsData);
        setAccounts(accountsData.result); // Assuming the API response structure
      } else {
        console.error('Failed to fetch accounts data', accountsData);
      }
    } catch (error) {
      console.error('Error fetching accounts data', error);
    } finally {
      setLoading(false);  // End loading
    }
  }, []);
  const handleLogout = async () => {
    try {
      await fetch('https://parseapi.back4app.com/logout', {
        method: 'POST',
        headers: {
          'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
          'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
          'X-Parse-Session-Token': sessionToken, // Use actual session token from state or context
        },
      });
      // Assuming logout was successful, navigate to home and clear any session info
      navigate('/');
      // Optionally clear sessionToken from state, context, or localStorage
    } catch (error) {
      console.error('Logout failed:', error);
      // Handle logout error (optional)
    }
  };
  const [openAddAccountDialog, setOpenAddAccountDialog] = useState(false);
  const [newAccountDetails, setNewAccountDetails] = useState({
    interest_rate: '40',
    description: t('MyNextAccount')
  });
  const [interestRateError, setInterestRateError] = useState('');
  const validateInterestRate = (value) => {
    const number = parseFloat(value);
    const isValid = !isNaN(number) && number >= 1 && number <= 200 && /^-?\d*(\.\d{0,2})?$/.test(value);
    
    if (!isValid) {
      setInterestRateError(t('InterestRateError'));
    } else {
      setInterestRateError('');
    }
    
    return isValid;
  };
  const handleInterestRateChange = (e) => {
    const value = e.target.value;
    // Update state only if the validation passes
    if (validateInterestRate(value) || value === '') {
      setNewAccountDetails({ ...newAccountDetails, interest_rate: value });
    }
  };
  const [descriptionError, setDescriptionError] = useState('');
  // Validate description
  const validateDescription = (value) => {
    const isValid = value.length >= 3 && value.length <= 50;

    if (!isValid) {
      setDescriptionError(t('AccountNameError'));
    } else {
      setDescriptionError('');
    }
    
    return isValid;
  };

  // Handle changes to the description field
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    // No immediate validation to allow user to type freely (optional: validate on blur or submit)
    setNewAccountDetails({ ...newAccountDetails, description: value });
  };

  const handleCreateAccount = async () => {
    const apiUrl = 'https://parseapi.back4app.com/functions/createAdditionalAccount';
    const data = {
      user: userId, // Use dynamically obtained userId
      interest_rate: parseInt(newAccountDetails.interest_rate, 10),
      description: newAccountDetails.description,
    };
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
          'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
      if (response.ok) {
        console.log('Account created successfully:', responseData);
        setOpenAddAccountDialog(false); // Close the dialog
        fetchAccounts(userId); // Refetch accounts data using the dynamic userId
      } else {
        console.error('Failed to create account', responseData);
      }
    } catch (error) {
      console.error('Error creating account', error);
    }
  };
  
  const handleChangeCurrency = async () => {
    const apiUrl = 'https://parseapi.back4app.com/functions/changeCurrencyForUserAccounts';
    const data = {
      user: userId, // Assuming you're using the dynamic userId state
      currency: selectedCurrency,
    };
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
          'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        console.log('Currency updated successfully');
        setOpenCurrencyDialog(false); // Close the dialog
        fetchAccounts(userId); // Refetch accounts data
      } else {
        console.error('Failed to update currency');
      }
    } catch (error) {
      console.error('Error updating currency', error);
    }
  };
  
  

  useEffect(() => {
    const fetchUserData = async () => {
      if (!sessionToken) {
        navigate('/login');
        return;
      }
  
      try {
        const response = await fetch('https://parseapi.back4app.com/users/me', {
          method: 'GET',
          headers: {
            'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
            'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
            'X-Parse-Session-Token': sessionToken,
          },
        });
  
        const userData = await response.json();
        if (response.ok) {
          setUserId(userData.objectId); // Update the userId state
          fetchAccounts(userData.objectId); // Pass userId obtained from userData to fetchAccounts
        } else {
          navigate('/login');
        }
      } catch (error) {
        navigate('/login');
      }
    };
  
    fetchUserData();
  }, [sessionToken, navigate, fetchAccounts]); // fetchAccounts is stable now, thanks to useCallback
  

  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundImage: `url(${process.env.PUBLIC_URL}/image_peanuts.webp)`, // Set the background image
        backgroundSize: 'cover', // Cover the entire page
        backgroundPosition: 'bottom', // Center the background image
        minHeight: '100vh'}}>
      <div className="centeredContent">
        <div style={{ margin: '100px 0 0', color: 'white' }}><h2>{t('Ahoj')}</h2></div>
        {loading ? (  // Show loading spinner while loading
          <CircularProgress style={{ color: 'white' }} />
        ) : (
          <>
            <div className="dashboardTiles">
              <Paper className="dashboardPaper" elevation={1} style={{ padding: '20px', marginBottom: '20px', backgroundColor: 'rgba(255, 224, 178, 0.8)', color: '#424242' }}>
                <Typography variant="h6">{t('TotalBalance')}: <strong>{new Intl.NumberFormat(i18n.language, {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalBalance)} {currency}</strong></Typography>
              </Paper>
              <Paper className="dashboardPaper" elevation={1} style={{ padding: '20px', marginBottom: '20px', backgroundColor: 'rgba(255, 224, 178, 0.8)', color: '#424242' }}>
                <Typography variant="h6">{t('TotalInterestEarned')}: <strong>{new Intl.NumberFormat(i18n.language, {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalInterestEarned)} {currency}</strong></Typography>
              </Paper>
            </div>
            <div className="accountsContainer">
              {accounts.map((account) => (
                <div 
                  className="accountTile" 
                  key={account.id} 
                  onClick={() => handleTileClick(account.id)}
                >
                  <div className="accountInfo">
                    <p>{account.description}</p>
                    <p>{t('InterestRate')}: {new Intl.NumberFormat(i18n.language, {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(account.interest_rate)}% p.a.</p>
                  </div>
                  <div className="accountBalance">
                    <p>
                      {new Intl.NumberFormat(i18n.language, {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(account.balance)} {account.currency}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div style={{ margin: '0 0 100px' }}></div>
          </>
        )}
      </div>
      {/* Logout FAB */}
    <Fab color="secondary" aria-label="logout" sx={{ position: 'fixed', bottom: 16, left: 16, backgroundColor: '#616161', '&:hover': { backgroundColor: '#e0e0e0' } }} onClick={handleOpenLogoutDialog}>
      <LogoutIcon />
    </Fab>

    {/* Logout Confirmation Dialog */}
    <Dialog open={openLogoutDialog} onClose={handleCloseLogoutDialog}>
      <DialogTitle>{t('LogoutConfirmationTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('AreYouSureYouWantToLogout')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseLogoutDialog}>{t('Cancel')}</Button>
        <Button onClick={() => { handleLogout(); handleCloseLogoutDialog(); }} color="primary" autoFocus>
          {t('Logout')}
        </Button>
      </DialogActions>
    </Dialog>
    
    {/* Add Account Dialog */}
    <Dialog open={openAddAccountDialog} onClose={() => setOpenAddAccountDialog(false)}>
      <DialogTitle>{t('AddNewAccount')}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="description"
          label={t('AccountName')}
          type="text"
          fullWidth
          variant="outlined"
          value={newAccountDetails.description}
          onChange={handleDescriptionChange}
          onBlur={() => validateDescription(newAccountDetails.description)} // Validate on blur
          error={!!descriptionError}
          helperText={descriptionError}
        />
        <TextField
          autoFocus
          margin="dense"
          id="interest_rate"
          label={t('InterestRate')}
          type="number"
          fullWidth
          variant="outlined"
          value={newAccountDetails.interest_rate}
          onChange={handleInterestRateChange}
          error={!!interestRateError}
          helperText={interestRateError}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenAddAccountDialog(false)}>{t('Cancel')}</Button>
        <Button onClick={handleCreateAccount} color="primary">
          {t('CreateAccount')}
        </Button>
      </DialogActions>
    </Dialog>

    {/* Add Account FAB */}
    {
      accounts.length < 4 && (
        <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: '#ff9800', '&:hover': { backgroundColor: '#e68900' } }} onClick={() => setOpenAddAccountDialog(true)}>
          <AddIcon />
        </Fab>
      )
    }

    <Dialog open={openCurrencyDialog} onClose={() => setOpenCurrencyDialog(false)}>
      <DialogTitle>{t('EditGlobalCurrency')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('CurrencyUpdateWarning')}
        </DialogContentText>
        <Autocomplete
          value={selectedCurrency}
          onChange={(event, newValue) => {
            // Check if newValue is null and set an empty string instead
            setSelectedCurrency(newValue || '');
          }}
          inputValue={selectedCurrency || ''} // Ensure inputValue is never null
          onInputChange={(event, newInputValue) => {
            // Check if newInputValue is null and set an empty string instead
            setSelectedCurrency(newInputValue || '');
          }}
          id="currency-autocomplete"
          options={currencies.map((option) => option.code)}
          renderInput={(params) => <TextField {...params} label={t('Currency')} />}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenCurrencyDialog(false)}>{t('Cancel')}</Button>
        <Button onClick={handleChangeCurrency} color="primary">
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
    <Fab color="primary" aria-label="edit-currency" sx={{ position: 'fixed', bottom: 16, left: 80, backgroundColor: '#616161', '&:hover': { backgroundColor: '#e0e0e0' } }} onClick={() => setOpenCurrencyDialog(true)}>
      <EditIcon /> {/* Import EditIcon from @mui/icons-material */}
    </Fab>
    
    </div>
  );
}

export default Dashboard;