import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import './App.css';
import { useNavigate } from 'react-router-dom';
import PARSE_CONFIG from './config';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

function CreateAccountPage() {
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput)); // Simple email regex validation
  };
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const handlePasswordChange = (event) => {
    const passwordInput = event.target.value;
    setPassword(passwordInput);
    setIsPasswordValid(passwordInput.length >= 4); // Validate password length
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/');
  };
  const { t } = useTranslation();
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    setLoading(true); // Start loading

    // Use the imported configuration variables
    const requestOptions = {
      method: 'POST',
      headers: {
        'X-Parse-Application-Id': PARSE_CONFIG.APPLICATION_ID,
        'X-Parse-REST-API-Key': PARSE_CONFIG.REST_API_KEY,
        'X-Parse-Revocable-Session': '1',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: email,
        password: password,
        email: email,
      }),
  };
  try {
    const response = await fetch('https://parseapi.back4app.com/users', requestOptions);
    const data = await response.json();
    if (response.ok) {
      // Handle successful account creation
      console.log('Account created successfully', data);
      navigate('/dashboard', { state: { sessionToken: data.sessionToken } }); // Redirect to Dashboard
    } else {
      // Handle errors (e.g., email already in use)
      console.error('Failed to create account', data);
      setErrorMessage(data.error || 'An unknown error occurred'); // Set the error message
      setIsErrorSnackbarOpen(true); // Show the Snackbar
    }
  } catch (error) {
    console.error('Error during account creation', error);
    setErrorMessage('An error occurred while trying to create the account.'); // Set a generic error message
    setIsErrorSnackbarOpen(true); // Show the Snackbar
  } finally {
    setLoading(false); // Stop loading regardless of the outcome
  }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="centeredContent">
        <div style={{ margin: '150px 0 0' }}><h2>{t('CreateAccount')}</h2></div>
        <form noValidate autoComplete="off" style={{ display: 'flex', flexDirection: 'column', gap: '16px'}} onSubmit={handleSubmit}>
          <TextField
            label={t('Email')}
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            error={!isEmailValid && email.length > 0} // Show error if email is not valid and not empty
            helperText={!isEmailValid && email.length > 0 ? t('InvalidEmail') : ''} // Assuming you have an 'InvalidEmail' entry in your i18n configuration
            />
          <TextField
            label={t('Password')}
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            onChange={handlePasswordChange}
            error={!isPasswordValid && password.length > 0} // Show error if password is not valid and not empty
            helperText={!isPasswordValid && password.length > 0 ? t('PasswordTooShort') : ''} // Assuming you have a 'PasswordTooShort' entry in your i18n configuration
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
                ),
            }}
            />
          <Button
            variant="contained"
            type="submit"
            disabled={!isEmailValid || email.length === 0 || !isPasswordValid || password.length === 0} // Disable button if email or password is invalid
            startIcon={loading && <CircularProgress size={20} color="inherit" />}
            sx={{
                backgroundColor: '#ff9800', 
                color: 'white', 
                '&:hover': {
                  backgroundColor: '#e68900',
                  color: 'white',
                },
                marginRight: '8px',
            }}
            >
            {t('CreateAccount')}
          </Button>
          {/* "Back" button */}
          <Button
            onClick={handleBack}
            variant="text"
            sx={{
                backgroundColor: 'white', 
                color: '#616161',
                marginRight: '8px',
            }}
            >
                {t('Back')}
          </Button>
        </form>
      </div>
      <Snackbar
        open={isErrorSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setIsErrorSnackbarOpen(false)}
        message={errorMessage}
      />
    </div>
    
  );
}

export default CreateAccountPage;
